





























/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
import { Component } from 'vue-property-decorator'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'

@Component<VerifyCommentsModal>({
  name: 'VerifyCommentsModal'
})

export class VerifyCommentsModal extends (AbstractModal) {
  /**
   * Timer index
   */
  private tIndex: number | null = 0

  public close (): void {
    this.tIndex = null
    this.closeCb()
  }

  public get CheckCircleIcon (): string {
    return require('./assets/CheckCircle.svg')
  }

  public get ShoppingCartSimpleIcon (): string {
    return require('./assets/ShoppingCartSimple.svg')
  }
}

export default VerifyCommentsModal
